/* eslint-disable jsx-a11y/no-onchange, jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';

import Panel from '../../../../../core/Panel';
import Heading from '../../../../../core/Heading';
import FeatureList from '../../../../../core/FeatureList';
import Columns from '../../../../../core/Columns';
import Column from '../../../../../core/Column';

import './index.css';

export class EverythingYouNeedPanel extends Component {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return (
      <div className="component everything-you-need-panel">
        <Panel backgroundColor="#f5f3f3">
          <Heading size="xlarge" level={1}>
            Everything you need and more.
          </Heading>

          <Columns>
            <Column>
              <FeatureList>
                <li>Property Management System</li>
                <li>Booking Engine</li>
                <li>Channel Manager</li>
                <li>Marketplace</li>
                <li>Revenue Management Tools</li>
              </FeatureList>
            </Column>
            <Column>
              <FeatureList>
                <li>Full Reporting Suite</li>
                <li>Simplify accounting</li>
                <li>Gift Certificates</li>
                <li>Upsell Items and Packages</li>
                <li>Housekeeping</li>
              </FeatureList>
            </Column>
            <Column>
              <FeatureList>
                <li>World class 24/7 support</li>
                <li>Easily manage rates</li>
                <li>Wait List</li>
                <li>Task Management</li>
                <li>and much more!</li>
              </FeatureList>
            </Column>
          </Columns>
        </Panel>
      </div>
    );
  }
}

export default EverythingYouNeedPanel;
