import React, { Component } from 'react';

import importImage from './images/Illustration_import.svg';

import './index.css';

class ImportDataPanel extends Component {
  render() {
    return (
      <div className="component import-data-panel">
        <div className="import-data-panel-title">
          <h2>We can import your data</h2>
        </div>
        <div className="import-data-panel-body">
          <div className="import-data-panel-body-content">
            <p>
              All your historical reservations — past and future — will be
              imported into ThinkReservations. Our goal is to make the
              transition so simple that one day you’re using your previous PMS
              and the next you’re using ThinkReservations with all your data at
              your fingertips. Our reservation management system makes tracking
              this information simple for hotels, B&Bs, and more.
            </p>
          </div>

          <div className="import-data-panel-body-image">
            <img src={importImage} alt="" />
          </div>
        </div>
      </div>
    );
  }
}

export default ImportDataPanel;
