import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import Link from '../../../core/Link';

import PricingPanel from './components/PricingPanel';
import EverythingYouNeedPanel from './components/EverythingYouNeedPanel';
import SetupPanel from './components/SetupPanel';
import ImportDataPanel from './components/ImportDataPanel';

import VideoCta from '../../../ui-library/VideoCta';

import './index.css';
import SingleTestimonial from '../../../ui-library/Testimonials/SingleTestimonial';
import eightDyer from '../../../../images/Dyer-78.jpeg';

export class PricingPage extends Component {
  static propTypes = {};

  onChangePriceCalculator(newValues) {
    this.setState(newValues);
  }

  render() {
    return (
      <App>
        <div className="component pricing-page">
          <Helmet title="Pricing – Hotel Reservation Management System – ThinkReservations">
            <meta
              name="description"
              content="Simple, upfront pricing makes upgrading to ThinkReservations an easy decision. Click for a customized quote!"
            />
          </Helmet>

          <div className="smooth-transition-container">
            <VideoCta
              videoId="588885838"
              headline="Value for your business."
              copy="Our focus at ThinkReservations is to provide you with rich functionality that will help you increase revenue and save time.  Our fully featured property management system, channel manager, and marketplace can help your business achieve and exceed its goals.  We would love to talk to you about your business and the value we can provide."
              children={
                <Fragment>
                  <Link to="/request-a-demo">
                    <button
                      className="block w-full my-4 rounded-md border border-transparent px-5 py-3 bg-[#e27d4e] text-base font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 sm:px-10"
                      type="submit"
                    >
                      Request pricing!
                    </button>
                  </Link>
                </Fragment>
              }
            />
          </div>

          <EverythingYouNeedPanel />

          <PricingPanel />

          <div className="testimonial-container">
            <SingleTestimonial
              quote={`ThinkReservations is easy to use, the conversion from our
            previous system was completely effortless, and we got to
            keep all of our historical data.`}
              author="Steve Katsurinis + Brandon Quesnell"
              company={'8 Dyer, Provincetown, MA'}
              Image={() => (
                <img
                  className="object-cover object-[65%] lg:h-full lg:w-full"
                  src={eightDyer}
                  alt=""
                />
              )}
            />
          </div>

          <SetupPanel />

          <ImportDataPanel />
        </div>
      </App>
    );
  }
}

export default PricingPage;
