import React, { Component } from 'react';
import Panel from '../../../../../core/Panel';

import './index.css';

import priceImage from './images/pricing image.svg';

export class PricingPanel extends Component {
  render() {
    return (
      <div className="component pricing-panel">
        <Panel>
          <div className="pricing-container">
            <div className="pricing-container-inner-wrapper">
              <div className="image-container">&nbsp;</div>
              <div className="verbiage-container">
                <strong>We can import your existing system data</strong>
                <p>
                  For an optional import fee, we can transition you seamlessly
                  and error free onto your new ThinkReservations system. You
                  keep all your historical data, and the cutover is instant.
                </p>
                <strong>Synchronize your listings with all major OTAs</strong>
                <p>
                  Eliminate double bookings and save time with our worry-free
                  fully automated Channel Manager. And we never charge
                  commission for reservations made on your website or over the
                  phone.
                </p>
              </div>
            </div>
          </div>
        </Panel>
      </div>
    );
  }
}

export default PricingPanel;
