import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

export class Columns extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    verticalAlign: PropTypes.oneOf(['top', 'bottom', 'middle']),
  };

  static defaultProps = {
    className: '',
    verticalAlign: 'middle',
  };

  render() {
    const { id, className, style = {}, children, verticalAlign } = this.props;

    let numberOfColumns = 0;
    React.Children.forEach(children, (columnChild) => {
      numberOfColumns = numberOfColumns + columnChild.props.colSpan;
    });

    const numberOfChildren = React.Children.count(children);
    const columnChildren = React.Children.map(
      children,
      (columnChild, columnChildIndex) => {
        const isFirst = columnChildIndex === 0;
        const isLast = columnChildIndex === numberOfChildren - 1;

        return React.cloneElement(columnChild, {
          gutterLeft: !isFirst,
          gutterRight: !isLast,
          numberOfColumns,
        });
      }
    );
    return (
      <div
        className={`component columns vertical-align-${verticalAlign} ${className}`.trim()}
        id={id}
        style={style}
      >
        {columnChildren}
      </div>
    );
  }
}

export default Columns;
