import React, { Component } from 'react';

import './index.css';

class SetupPanel extends Component {
  render() {
    return (
      <div className="component setup-panel">
        <div className="setup-panel-title">
          <h2>What's included in your setup?</h2>
        </div>
        <div className="setup-panel-icons">
          <div className="setup-panel-icon account-manager">
            <div className="icon-title">Account Manager</div>
            <div className="icon-description">
              who configures your ThinkReservations account for you
            </div>
          </div>
          <div className="setup-panel-icon training">
            <div className="icon-title">On-demand training</div>
            <div className="icon-description">
              for you and your staff, plus a live virtual launch meeting
            </div>
          </div>
          <div className="setup-panel-icon brand">
            <div className="icon-title">Match your brand</div>
            <div className="icon-description">
              with your booking engine, gift certificates, and emails styled for
              you
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SetupPanel;
