import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

export class Heading extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    level: PropTypes.number,
    size: PropTypes.oneOf(['normal', 'large', 'xlarge']),
  };

  static defaultProps = {
    level: 3,
    size: 'normal',
    className: '',
  };

  render() {
    const { id, className, style, level, size } = this.props;

    let tagName = 'h3';
    if (level === 1) {
      tagName = 'h1';
    } else if (level === 2) {
      tagName = 'h2';
    } else if (level === 3) {
      tagName = 'h3';
    } else if (level === 4) {
      tagName = 'h4';
    } else if (level === 5) {
      tagName = 'h5';
    } else if (level === 6) {
      tagName = 'h6';
    }

    return React.createElement(
      tagName,
      {
        className: `component heading ${className} size-${size}`,
        id: id,
        style: style,
      },
      this.props.children
    );
  }
}

export default Heading;
