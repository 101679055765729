import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

export class Column extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    gutterLeft: PropTypes.bool,
    gutterRight: PropTypes.bool,
    colSpan: PropTypes.number,
    numberOfColumns: PropTypes.number,
  };

  static defaultProps = {
    className: '',
    gutterLeft: false,
    gutterRight: false,
    colSpan: 1,
    numberOfColumns: 1,
  };

  render() {
    const {
      id,
      className,
      gutterLeft,
      gutterRight,
      colSpan,
      numberOfColumns,
    } = this.props;

    const componentStyles = {
      width: `${((colSpan * 100) / numberOfColumns).toFixed(3)}%`,
    };

    return (
      <div
        className={`component column ${className} ${
          gutterLeft ? 'gutter-left' : ''
        } ${gutterRight ? 'gutter-right' : ''}`.trim()}
        id={id}
        style={componentStyles}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Column;
