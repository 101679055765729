import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

export class FeatureList extends Component {
  static propTypes = {
    bulletColor: PropTypes.oneOf(['white', 'black']),
  };

  static defaultProps = {
    bulletColor: 'black',
  };

  render() {
    const { bulletColor } = this.props;

    return (
      <div className="component feature-list">
        <ul className={`feature-list bullet-color-${bulletColor}`}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

export default FeatureList;
